<template>
  <main class="other-auth">
    <transition name="fade" mode="out-in">
      <div class="spinner" v-if="!verified" key="spin">
        <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
      </div>
      <main class="other-auth" v-else key="v">
        <div class="text">
          <i class="fa-solid fa-check fa-4x"></i>
          <span class="title">
            You have successfully verified your email address!
          </span>
        </div>
        <div class="continue">
          <router-link to="/profile">Proceed</router-link>
        </div>
      </main>
    </transition>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    verified: false,
  }),
  created() {
    let token = this.$route.params.token;
    if (!token) return this.$router.push("/");
    axios
      .post(this.$api("/user/verify"), {
        token,
      })
      .then((res) => {
        this.verified = true;
        this.$store.commit("setToken", res.data.token);
        this.$store.dispatch("getUser");
      })
      .catch(err => {
        console.log("verify email error", err);
      });
  }
}
</script>

<style>

</style>